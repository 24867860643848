// src/pages/Projects.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../components/MainLayout';
import ProjectsLayout from '../styles/ProjectsLayout';
import { projectStatusCodes } from '../utils/statusCodes'; // Import status code mapping

const Projects = () => {
  const [userData, setUserData] = useState(null);
  const [data, setData] = useState([]); // Holds project data
  const [searchTerm, setSearchTerm] = useState(''); // Holds the search term
  const [selectedStatus, setSelectedStatus] = useState(''); // Holds the selected status code for filtering
  const [selected, setSelected] = useState([]); // Keeps track of selected projects
  const [statusCodes, setStatusCodes] = useState([]); // Holds unique status codes for dropdown

  // Fetch project data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data from your Node.js backend, sending cookies with the request
        const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
          withCredentials: true, // Include cookies in the request
        });
        setUserData(userResponse.data);

        // Fetch projects data from API
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/dataverse/fw_projects`, {
          withCredentials: true, // Include cookies in the request
        });

        const projects = response.data;

        // Extract unique status codes for filtering options
        const uniqueStatusCodes = [...new Set(projects.map(project => project.statuscode))];
        setStatusCodes(uniqueStatusCodes);

        // Sort projects by createdon date (most recent first)
        const sortedProjects = projects.sort((a, b) => new Date(b.createdon) - new Date(a.createdon));

        setData(sortedProjects); // Set sorted project data
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchData();
  }, []);

  // Handle project selection
  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle filter change based on status code
  const handleStatusFilterChange = (event) => {
    setSelectedStatus(event.target.value); // Update selected status code
  };

  // Filter projects based on the search term and selected status code
  const filteredProjects = data
    .filter(project =>
      (project.fw_name && project.fw_name.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (selectedStatus ? project.statuscode === selectedStatus : true) // Apply status filter
    );

  return (
    <MainLayout userData={userData}> {/* Pass userData to MainLayout for use in the header */}
      {/* Pass the necessary props to the layout */}
      <ProjectsLayout
        data={filteredProjects.map(project => ({
          internalId: project.fw_projectnumber || 'N/A',
          externalId: project.fw_name || 'N/A',
          statuscode: projectStatusCodes[project.statuscode] || 'Unknown', // Map status code to its label
          deadline: project.fw_deadline || 'N/A',
          createdOn: project.createdon || 'N/A',
          imageUrl: project.fw_account?.entityimage
            ? `data:image/png;base64,${project.fw_account.entityimage}`
            : '' // If image exists, format it as a data URL
        }))}
        selected={selected}
        handleSelect={handleSelect}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedStatus={selectedStatus}
        handleStatusFilterChange={handleStatusFilterChange}
        statusCodes={statusCodes.map(code => projectStatusCodes[code] || 'Unknown')} // Convert status codes for dropdown
      />
    </MainLayout>
  );
};

export default Projects;
