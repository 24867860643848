import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import LoginLayout from '../components/LoginLayout';  // Import the layout component
import companyLogo from '../images/Picture1.png';  // Company logo


const Login = () => {
  const handleLogin = () => {
    window.location.href = process.env.REACT_APP_AZURE_REDIRECT_URI;
  };

  return (
    <LoginLayout> {/* Wrapping the form inside the layout */}
      <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '100px' }}>
        {/* Display the company logo */}
        <img src={companyLogo} alt="Company Logo" style={{ width: '150px', marginBottom: '20px' }} />
        
        <Typography variant="h4" gutterBottom>
          Welcome to Guardian Edge
        </Typography>
        
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login with Microsoft
        </Button>
      </Container>
    </LoginLayout>
  );
};

export default Login;
