import React, { useState } from 'react';
import {
  Grid, Card, CardContent, Typography, Checkbox, Box, TextField, IconButton, Menu, MenuItem
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';
import { projectStatusCodes } from '../utils/statusCodes';  // Import the status code mapping

const ProjectsLayout = ({
  data, selected, handleSelect, searchTerm, setSearchTerm, selectedStatus, handleStatusFilterChange, statusCodes
}) => {
  const isSelected = (id) => selected.includes(id);
  const navigate = useNavigate();

  // Handle project click to navigate to details page
  const handleProjectClick = (projectId) => {
    navigate(`/project-details/${projectId}`);
  };

  // State to manage the dropdown menu for the status filter
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* Search Bar */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <TextField
          label="Search Projects"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* Filter Icon Button */}
        <IconButton onClick={handleMenuOpen} sx={{ marginLeft: 2 }}>
          <FilterListIcon />
        </IconButton>
      </Box>

      {/* Status Filter Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{ zIndex: 9999 }}
      >
        <MenuItem value="">
          <em>All Statuses</em>
        </MenuItem>
        {statusCodes.map((statusCode, index) => (
          <MenuItem key={index} value={statusCode} onClick={(e) => {
            handleStatusFilterChange(e);
            handleMenuClose();
          }}>
            {statusCode}
          </MenuItem>
        ))}
      </Menu>

      {/* Projects Grid */}
      <Grid container spacing={4}>
        {data.length > 0 ? (
          data.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project.internalId}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #ccc', cursor: 'pointer' }}
                onClick={() => handleProjectClick(project.internalId)}  // Handle click to navigate to details page
              >
                <CardContent>
                  {/* Display internalId - externalId */}
                  <Typography variant="h6" gutterBottom>
                    {`${project.internalId} - ${project.externalId}`}
                  </Typography>

                  {/* Display formatted statuscode from the hardcoded status codes */}
                  <Typography variant="body2" color="text.secondary">
                    Status: {projectStatusCodes[project.statuscode] || 'No status available'}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    Deadline: {project.deadline ? new Date(project.deadline).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : 'No deadline available'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Created On: {project.createdOn ? new Date(project.createdOn).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : 'Unknown'}
                  </Typography>

                  {/* Display image if available */}
                  {project.imageUrl && (
                    <img src={project.imageUrl} alt="Project" style={{ width: '100%', marginTop: '10px' }} />
                  )}

                  {/* Select Project */}
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Checkbox
                      checked={isSelected(project.internalId)}
                      onChange={(e) => {
                        e.stopPropagation(); // Prevent navigating when clicking on the checkbox
                        handleSelect(project.internalId);
                      }}
                      inputProps={{ 'aria-label': 'select project' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      Select Project
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" align="center" sx={{ marginTop: 4 }}>
            No projects found.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default ProjectsLayout;
