// src/pages/PurchaseOrders.js
import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import MainLayout from '../components/MainLayout';
import PurchaseOrdersLayout from '../styles/PurchaseOrdersLayout'; // Import the PurchaseOrdersLayout component

const PurchaseOrders = () => {
  const { instance, accounts } = useMsal(); // MSAL instance and account information
  const [data, setData] = useState([]); // Holds purchase orders data
  const [searchTerm, setSearchTerm] = useState(''); // Holds the search term
  const [selected, setSelected] = useState([]); // Keeps track of selected purchase orders

  // Fetch purchase orders data from Dataverse
  useEffect(() => {
    const fetchData = async () => {
      if (accounts.length > 0) {
        const request = {
          scopes: [process.env.REACT_APP_DATAVERSE_SCOPE],
          account: accounts[0],
        };

        try {
          // Acquire token silently
          const tokenResponse = await instance.acquireTokenSilent(request);
          const accessToken = tokenResponse.accessToken;

          // Fetch data from Dataverse
          const response = await axios.get(
            `${process.env.REACT_APP_DATAVERSE_URL}/api/data/v9.1/purchaseorders`, // Dataverse purchase orders endpoint
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          setData(response.data.value); // Set purchase orders data from Dataverse
        } catch (error) {
          console.error('Error fetching purchase orders from Dataverse:', error);
        }
      }
    };

    fetchData();
  }, [instance, accounts]);

  // Handle purchase order selection
  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Filter purchase orders based on the search term
  const filteredOrders = data.filter((order) =>
    order.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <MainLayout>
      {/* Pass the necessary props to the layout */}
      <PurchaseOrdersLayout
        data={filteredOrders}
        selected={selected}
        handleSelect={handleSelect}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </MainLayout>
  );
};

export default PurchaseOrders;
