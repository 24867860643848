// src/pages/Accounts.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../components/MainLayout';
import AccountsLayout from '../styles/AccountsLayout'; // Import layout from the styles folder

const Accounts = () => {  
	const [userData, setUserData] = useState(null);
	const [data, setData] = useState([]); // Holds account data
	const [searchTerm, setSearchTerm] = useState(''); // Holds the search term
	const [selected, setSelected] = useState([]); // Keeps track of selected accounts

// Fetch Accounts data from the new API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {		  
		// Fetch user data from your Node.js backend, sending cookies with the request
        const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
          withCredentials: true, // Include cookies in the request
        });
        setUserData(userResponse.data);  
        // Fetch data from /api/dataverse/accounts
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/dataverse/accounts`, {
          withCredentials: true, // Include cookies in the request
        });

        setData(response.data); // Set client accounts data
      } catch (error) {
        console.error('Error fetching client accounts:', error);
      }
    };

    fetchData();
  }, []);

  // Handle account selection
  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Filter accounts based on the search term
  const filteredAccounts = data.filter((account) =>
    account.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <MainLayout userData={userData}> {/* Pass userData to MainLayout for use in the header */}
      {/* Pass the necessary props to the layout */}
      <AccountsLayout
        data={filteredAccounts.map(account => ({
			name: account.name || 'N/A',
			phone: account.telephone1 || 'N/A',
			id: account.accountid
			
		}))}
        selected={selected}
        handleSelect={handleSelect}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </MainLayout>
  );
};

export default Accounts;
