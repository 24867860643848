// src/styles/AccountsLayout.js
import React from 'react';
import {
  Grid, Card, CardContent, Typography, TextField, Checkbox, CardMedia, Box
} from '@mui/material';

const AccountsLayout = ({ data, selected, handleSelect, searchTerm, setSearchTerm }) => {
  // Check if an account is selected
  const isSelected = (id) => selected.includes(id);

  return (
    <>
      {/* Search Bar */}
      <TextField
        label="Search Accounts"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Accounts Grid */}
      <Grid container spacing={4}>
        {data.length > 0 ? (
          data.map((account) => (
            <Grid item xs={12} sm={6} md={4} key={account.accountid}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #ccc' }}
              >
                <CardMedia
				component="img"
				height="140"
				image={account.imageUrl 
				? `data:image/png;base64,${account.imageUrl}` 
				: 'https://via.placeholder.com/150'} // Placeholder if no image
				alt={account.name}
				/>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {account.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Internal Reference: {account.crffc_ainternalreference || 'N/A'}
                  </Typography>
				  <Typography variant="body2" color="text.secondary">
                    Email: {account.emailaddress1 || 'N/A'}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Checkbox
                      checked={isSelected(account.accountid)}
                      onChange={() => handleSelect(account.accountid)}
                      inputProps={{ 'aria-label': 'select account' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      Select Account
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" align="center" sx={{ marginTop: 4 }}>
            No accounts found.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default AccountsLayout;
