import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import MainLayout from '../components/MainLayout';
import CCTVInstallationLayout from '../styles/CCTVInstallationLayout'; // Import the layout component
import { Box, Typography } from '@mui/material'; // Import material components for styling

const CCTVInstallation = () => {
  const { instance, accounts } = useMsal(); // MSAL instance and account information
  const [data, setData] = useState([]); // Holds CCTV installations data
  const [searchTerm, setSearchTerm] = useState(''); // Holds the search term
  const [selected, setSelected] = useState([]); // Keeps track of selected installations
  const [quote, setQuote] = useState(''); // Holds the fetched quote
  const [author, setAuthor] = useState(''); // Holds the author of the fetched quote

  // Fetch CCTV installation data from Dataverse
  useEffect(() => {
    const fetchData = async () => {
      if (accounts.length > 0) {
        const request = {
          scopes: [process.env.REACT_APP_DATAVERSE_SCOPE],
          account: accounts[0],
        };

        try {
          const tokenResponse = await instance.acquireTokenSilent(request);
          const accessToken = tokenResponse.accessToken;

          const response = await axios.get(
            `${process.env.REACT_APP_DATAVERSE_URL}/api/data/v9.1/cctv_installations`, 
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          setData(response.data.value); // Set CCTV installation data from Dataverse
        } catch (error) {
          console.error('Error fetching CCTV installations from Dataverse:', error);
        }
      }
    };

    fetchData();
  }, [instance, accounts]);

  // Fetch random quote from ZenQuotes API
  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const response = await axios.get('https://zenquotes.io/api/random');
        const randomQuote = response.data[0];
        setQuote(randomQuote.q);
        setAuthor(randomQuote.a);
      } catch (error) {
        console.error('Error fetching the quote:', error);
      }
    };

    fetchQuote();
  }, []);

  // Handle installation selection
  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Filter installations based on the search term
  const filteredInstallations = data.filter((installation) =>
    installation.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <MainLayout>
      {/* Display the random quote */}
      <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          {quote}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          — {author}
        </Typography>
      </Box>

      {/* Pass the necessary props to the layout */}
      <CCTVInstallationLayout
        data={filteredInstallations}
        selected={selected}
        handleSelect={handleSelect}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </MainLayout>
  );
};

export default CCTVInstallation;
