// src/styles/FireSafetyLayout.js
import React from 'react';
import {
  Grid, Card, CardContent, Typography, TextField, Checkbox, Box
} from '@mui/material';

const FireSafetyLayout = ({ data, selected, handleSelect, searchTerm, setSearchTerm }) => {
  const isSelected = (id) => selected.includes(id);

  return (
    <>
      {/* Search Bar */}
      <TextField
        label="Search Fire Safety"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Fire Safety Records Grid */}
      <Grid container spacing={4}>
        {data.length > 0 ? (
          data.map((record) => (
            <Grid item xs={12} sm={6} md={4} key={record.recordid}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #ccc' }}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {record.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Description: {record.description || 'No description available'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Date: {record.date || 'N/A'}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Checkbox
                      checked={isSelected(record.recordid)}
                      onChange={() => handleSelect(record.recordid)}
                      inputProps={{ 'aria-label': 'select fire safety record' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      Select Record
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" align="center" sx={{ marginTop: 4 }}>
            No fire safety records found.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default FireSafetyLayout;
