// src/styles/PurchaseOrdersLayout.js
import React from 'react';
import {
  Grid, Card, CardContent, Typography, TextField, Checkbox, CardMedia, Box
} from '@mui/material';

const PurchaseOrdersLayout = ({ data, selected, handleSelect, searchTerm, setSearchTerm }) => {
  // Check if a purchase order is selected
  const isSelected = (id) => selected.includes(id);

  return (
    <>
      {/* Search Bar */}
      <TextField
        label="Search Purchase Orders"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Purchase Orders Grid */}
      <Grid container spacing={4}>
        {data.length > 0 ? (
          data.map((order) => (
            <Grid item xs={12} sm={6} md={4} key={order.purchaseorderid}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #ccc' }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={order.imageUrl || 'https://via.placeholder.com/150'} // Placeholder if no image
                  alt={order.name}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {order.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Amount: {order.totalamount || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Due Date: {order.duedate || 'N/A'}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Checkbox
                      checked={isSelected(order.purchaseorderid)}
                      onChange={() => handleSelect(order.purchaseorderid)}
                      inputProps={{ 'aria-label': 'select purchase order' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      Select Purchase Order
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" align="center" sx={{ marginTop: 4 }}>
            No purchase orders found.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default PurchaseOrdersLayout;
