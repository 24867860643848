import React from 'react';
import { Box, AppBar, Toolbar, Typography, CssBaseline } from '@mui/material';
import Sidebar from '../components/Sidebar';
import ScrollingText from '../components/ScrollingText';
import logo from '../images/Picture1.png';
import '../css/MainLayout.css';

const drawerWidth = 15;

const MainLayout = ({ children, userData, messages }) => {
  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <CssBaseline />
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, marginLeft: `${drawerWidth}px`, position: 'relative' }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#650707' }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="Logo" className="header-logo" />
              <Typography variant="h4" noWrap>
                GEMS
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Scrolling text positioned absolutely */}
        <Box sx={{ position: 'absolute', top: 100, left: 0, width: '100%', zIndex: 5 }}>
          <ScrollingText messages={messages} />
        </Box>

        {/* Sloped Yellow Box */}
        <Box className="yellow-box" sx={{ zIndex: 10 }}> {/* Yellow box appears on top of the scrolling text */}
          <Box className="user-info">
            <Typography variant="h6">{userData?.displayName || 'No Name'}</Typography>
            <Typography variant="body1">{userData?.internalemailaddress || userData?.userPrincipalName || 'No Email'}</Typography>
            <Typography variant="body2">Last Login: {userData?.lastLoginTime || 'Unknown'}</Typography>
          </Box>
          <Box>
            <img src={userData?.Image || { logo }} alt="User Profile" className="user-image" />
          </Box>
        </Box>

        {/* Main Content */}
        <Box className="main-content">
          {children}
        </Box>
      </Box>

      {/* Footer */}
      <Box component="footer" className="footer" sx={{ marginLeft: `${drawerWidth}px` }}>
        <Typography variant="body2" color="white">
          &copy; 2024 Guardian Edge Ltd. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default MainLayout;
