// src/pages/UserProfile.js
import React, { useState, useEffect } from 'react';
import { TextField, Box, Button, Typography, Card, CardMedia, CardContent, CardActions, Grid } from '@mui/material';
import axios from 'axios';
import MainLayout from '../components/MainLayout'; // Import MainLayout for page structure
import '../css/UserProfile.css'; // Import the CSS for styling

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [contactDetails, setContactDetails] = useState({ address: '', phone: '' });

  useEffect(() => {
    // Fetch user data from API
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, { withCredentials: true });
        setUserData(response.data);
        setContactDetails({
          address: response.data.address || '',
          phone: response.data.phone || '',
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/user/update`, contactDetails, { withCredentials: true });
      setEditMode(false);
    } catch (error) {
      console.error('Error updating contact details:', error);
    }
  };

  if (!userData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Card className="profile-card" sx={{ maxWidth: 600, width: '100%' }}>
          {/* Profile Picture */}
          <CardMedia
            component="img"
            height="300"
            image={userData.profileImageUrl || 'https://via.placeholder.com/300'} // Fallback if no image
            alt={userData.displayName}
          />
          <CardContent>
            {/* Profile Info */}
            <Typography variant="h5" gutterBottom>
              {userData.displayName}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Email: {userData.email}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Position: {userData.position || 'N/A'}
            </Typography>

            {/* Editable Fields */}
            <TextField
              label="Address"
              name="address"
              value={contactDetails.address}
              onChange={handleInputChange}
              margin="normal"
              fullWidth
              disabled={!editMode}
            />
            <TextField
              label="Phone"
              name="phone"
              value={contactDetails.phone}
              onChange={handleInputChange}
              margin="normal"
              fullWidth
              disabled={!editMode}
            />
          </CardContent>

          <CardActions>
            {editMode ? (
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Save Changes
              </Button>
            ) : (
              <Button variant="outlined" color="primary" onClick={() => setEditMode(true)}>
                Edit Contact Info
              </Button>
            )}
          </CardActions>
        </Card>
      </Box>
    </MainLayout>
  );
};

export default UserProfile;
