// src/styles/PropertiesLayout.js
import React from 'react';
import {
  Grid, Card, CardContent, Typography, TextField, Checkbox, Box
} from '@mui/material';

const PropertiesLayout = ({ data, selected, handleSelect, searchTerm, setSearchTerm }) => {
  const isSelected = (id) => selected.includes(id);

  return (
    <>
      {/* Search Bar */}
      <TextField
        label="Search Properties"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Properties Grid */}
      <Grid container spacing={4}>
        {data.length > 0 ? (
          data.map((property) => (
            <Grid item xs={12} sm={6} md={4} key={property.propertyid}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #ccc' }}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {property.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Address: {property.address || 'No address available'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Type: {property.type || 'N/A'}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Checkbox
                      checked={isSelected(property.propertyid)}
                      onChange={() => handleSelect(property.propertyid)}
                      inputProps={{ 'aria-label': 'select property' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      Select Property
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" align="center" sx={{ marginTop: 4 }}>
            No properties found.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default PropertiesLayout;
