// src/pages/Clientcontacts.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../components/MainLayout';
import ClientContactsLayout from '../styles/ClientContactsLayout'; // Import the layout component

const ClientContacts = () => {
	const [userData, setUserData] = useState(null);
	const [data, setData] = useState([]); // Holds client contacts data
	const [searchTerm, setSearchTerm] = useState(''); // Holds the search term
	const [selected, setSelected] = useState([]); // Keeps track of selected contacts

  // Fetch client contacts data from the new API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
		// Fetch user data from your Node.js backend, sending cookies with the request
        const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
          withCredentials: true, // Include cookies in the request
        });
        setUserData(userResponse.data);		
        // Fetch data from /api/dataverse/contacts
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/dataverse/contacts`, {
          withCredentials: true, // Include cookies in the request
        });

        setData(response.data); // Set client contacts data
      } catch (error) {
        console.error('Error fetching client contacts:', error);
      }
    };

    fetchData();
  }, []);

  // Handle client contact selection
  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Filter client contacts based on the search term
  const filteredContacts = data.filter((contact) =>
    contact.yomifullname?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <MainLayout userData={userData}> {/* Pass userData to MainLayout for use in the header */}
      {/* Pass the necessary props to the layout */}
      <ClientContactsLayout
        data={filteredContacts.map(contact => ({
          name: contact.yomifullname || 'N/A',
		  account: contact?.fw_parentaccount?.name || 'N/A',
          phone: contact.telephone1 || 'N/A',
          position: contact.jobtitle || 'N/A',
          email: contact.emailaddress1 || 'N/A',
          imageUrl: contact.fw_parentaccount?.entityimage
          ? `data:image/png;base64,${contact.fw_parentaccount.entityimage}`
          : '', // If image exists, format it as a data URL
          id: contact.contactid
        }))}
        selected={selected}
        handleSelect={handleSelect}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </MainLayout>
  );
};

export default ClientContacts;
