// src/styles/SuppliersLayout.js
import React from 'react';
import {
  Grid, Card, CardContent, Typography, TextField, Checkbox, CardMedia, Box
} from '@mui/material';

const SuppliersLayout = ({ data, selected, handleSelect, searchTerm, setSearchTerm }) => {
  // Check if a supplier is selected
  const isSelected = (id) => selected.includes(id);

  return (
    <>
      {/* Search Bar */}
      <TextField
        label="Search Suppliers"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Suppliers Grid */}
      <Grid container spacing={4}>
        {data.length > 0 ? (
          data.map((supplier) => (
            <Grid item xs={12} sm={6} md={4} key={supplier.accountid}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #ccc' }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={supplier.imageUrl || 'https://via.placeholder.com/150'} // Placeholder if no image
                  alt={supplier.name}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {supplier.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Internal Reference: {supplier.accountnumber || 'N/A'}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Checkbox
                      checked={isSelected(supplier.accountid)}
                      onChange={() => handleSelect(supplier.accountid)}
                      inputProps={{ 'aria-label': 'select supplier' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      Select Supplier
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" align="center" sx={{ marginTop: 4 }}>
            No suppliers found.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default SuppliersLayout;
