import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Checkbox, TextField } from '@mui/material';

const QuotesLayout = ({ data, selected, handleSelect, searchTerm, setSearchTerm }) => {
  return (
    <Container maxWidth="lg">  {/* Ensure it's full width */}
      <TextField
        fullWidth
        label="Search Quotes"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 3 }}
      />
      <Grid container spacing={3}>
        {data.length > 0 ? (
          data.map((quote) => (
            <Grid item xs={12} sm={6} md={4} key={quote.quoteid}>
              <Card variant="outlined">
                <CardContent>
                  <Checkbox
                    checked={selected.includes(quote.quoteid)}
                    onChange={() => handleSelect(quote.quoteid)}
                  />
                  <Typography variant="h6">{quote.name}</Typography>
                  <Typography variant="body2">Status: {quote.statuscode}</Typography>
                  <Typography variant="body2">Total: {quote.totalamount} USD</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" align="center">
            No quotes found.
          </Typography>
        )}
      </Grid>
    </Container>
  );
};

export default QuotesLayout;
