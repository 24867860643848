// src/pages/Suppliers.js
import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import MainLayout from '../components/MainLayout';
import SuppliersLayout from '../styles/SuppliersLayout'; // Import layout from the styles folder

const Suppliers = () => {
  const { instance, accounts } = useMsal(); // MSAL instance and account information
  const [data, setData] = useState([]); // Holds supplier data
  const [searchTerm, setSearchTerm] = useState(''); // Holds the search term
  const [selected, setSelected] = useState([]); // Keeps track of selected suppliers

  // Fetch supplier data from Dataverse
  useEffect(() => {
    const fetchData = async () => {
      if (accounts.length > 0) {
        const request = {
          scopes: [process.env.REACT_APP_DATAVERSE_SCOPE],
          account: accounts[0],
        };

        try {
          // Acquire token silently
          const tokenResponse = await instance.acquireTokenSilent(request);
          const accessToken = tokenResponse.accessToken;

          // Fetch data from Dataverse
          const response = await axios.get(
            `${process.env.REACT_APP_DATAVERSE_URL}/api/data/v9.1/accounts`, // Dataverse suppliers endpoint
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          setData(response.data.value); // Set supplier data from Dataverse
        } catch (error) {
          console.error('Error fetching suppliers from Dataverse:', error);
        }
      }
    };

    fetchData();
  }, [instance, accounts]);

  // Handle supplier selection
  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Filter suppliers based on the search term
  const filteredSuppliers = data.filter((supplier) =>
    supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <MainLayout>
      {/* Pass the necessary props to the layout */}
      <SuppliersLayout
        data={filteredSuppliers}
        selected={selected}
        handleSelect={handleSelect}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </MainLayout>
  );
};

export default Suppliers;
