import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Divider, Accordion, AccordionSummary, AccordionDetails, Box, Toolbar, ListItemIcon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useNavigate } from 'react-router-dom'; // For navigation
import sidebarItems from './SidebarItems'; // Import your sidebar items
import '../css/Sidebar.css'; // Custom styles

const drawerWidth = 300;

const Sidebar = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  // Handle accordion expansion
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/'); // Redirect to login
  };

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: 'drawer-paper' }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {sidebarItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.subItems ? (
                // Render Accordion if the item has subItems
                <Accordion expanded={expanded === item.panel} onChange={handleAccordionChange(item.panel)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${item.panel}-content`} id={`${item.panel}-header`}>
                    <ListItemIcon>{item.icon}</ListItemIcon> {/* Render the icon */}
                    <ListItemText primary={item.label} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {item.subItems.map((subItem, subIndex) => (
                        <ListItem button key={subIndex} component={Link} to={subItem.link}>
                          <ListItemIcon>{subItem.icon}</ListItemIcon> {/* Render the subItem icon */}
                          <ListItemText primary={subItem.label} />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ) : (
                // Render simple list items (e.g. Settings, Logout)
                <ListItem button key={index} component={Link} to={item.link || '#'} onClick={item.action === 'logout' ? handleLogout : undefined}>
                  <ListItemIcon>{item.icon}</ListItemIcon> {/* Render the icon */}
                  <ListItemText primary={item.label} />
                </ListItem>
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
