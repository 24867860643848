// src/utils/statusCodes.js

// Mapping of status codes to labels for fw_projects
export const projectStatusCodes = {
  1: 'Not Started',
  267070000: 'In Process',
  267070001: 'Delayed',
  347010001: 'Template',
  427760001: 'Completed',
  127760001: 'Booked',
  127760003: 'Overdue',
  127760004: 'Waiting on Contractor',
  127760002: 'Further Work Required',
  2: 'Closed',
  267070002: 'Cancelled'
};

// You can create similar mappings for other tables or statuses
// Example for another entity, e.g., fw_contacts
export const contactStatusCodes = {
  1: 'Active',
  2: 'Inactive',
  // Add more as needed...
};
