// src/pages/SuppliersContacts.js
import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import MainLayout from '../components/MainLayout';
import SuppliersContactsLayout from '../styles/SuppliersContactsLayout'; // Import layout from the styles folder

const SuppliersContacts = () => {
  const { instance, accounts } = useMsal(); // MSAL instance and account information
  const [data, setData] = useState([]); // Holds supplier contacts data
  const [searchTerm, setSearchTerm] = useState(''); // Holds the search term
  const [selected, setSelected] = useState([]); // Keeps track of selected suppliers contacts

  // Fetch supplier contacts data from Dataverse
  useEffect(() => {
    const fetchData = async () => {
      if (accounts.length > 0) {
        const request = {
          scopes: [process.env.REACT_APP_DATAVERSE_SCOPE],
          account: accounts[0],
        };

        try {
          // Acquire token silently
          const tokenResponse = await instance.acquireTokenSilent(request);
          const accessToken = tokenResponse.accessToken;

          // Fetch data from Dataverse
          const response = await axios.get(
            `${process.env.REACT_APP_DATAVERSE_URL}/api/data/v9.1/suppliers_contacts`, // Dataverse contacts endpoint
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          setData(response.data.value); // Set supplier contacts data from Dataverse
        } catch (error) {
          console.error('Error fetching suppliers contacts from Dataverse:', error);
        }
      }
    };

    fetchData();
  }, [instance, accounts]);

  // Handle supplier contact selection
  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Filter supplier contacts based on the search term
  const filteredContacts = data.filter((contact) =>
    contact.fullname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <MainLayout>
      {/* Pass the necessary props to the layout */}
      <SuppliersContactsLayout
        data={filteredContacts}
        selected={selected}
        handleSelect={handleSelect}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </MainLayout>
  );
};

export default SuppliersContacts;
