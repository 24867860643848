import React, { useEffect, useState } from 'react';
import { Typography, Container, Grid, Paper, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import MainLayout from '../components/MainLayout'; // Import the MainLayout

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [dataverseData, setDataverseData] = useState(null);
  const [loading, setLoading] = useState(true);  // Loading state
  const [error, setError] = useState(null);      // Error state

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data from your Node.js backend, sending cookies with the request
        const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
          withCredentials: true, // Include cookies in the request
        });
        setUserData(userResponse.data);
    
        // Fetch Dataverse data from your Node.js backend, sending cookies with the request
        const dataverseResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/dataverse/contacts`, {
          withCredentials: true, // Include cookies in the request
        });
        setDataverseData(dataverseResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again.');
      } finally {
        setLoading(false)
        ;
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <MainLayout>
        <Container maxWidth="lg">
          <CircularProgress />
          <Typography variant="h6">Loading dashboard data...</Typography>
        </Container>
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <Container maxWidth="lg">
          <Alert severity="error">{error}</Alert>
        </Container>
      </MainLayout>
    );
  }

  return (
    <MainLayout userData={userData}> {/* Pass userData to MainLayout for use in the header */}
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom align="left">
          Dashboard
        </Typography>

        <Grid container spacing={3} className="grid-container">
          {[...Array(9)].map((_, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper elevation={3} className="grid-item">
                <Typography variant="h6">Chart {index + 1}</Typography>
                {dataverseData && dataverseData[index] ? (
                  <pre>{JSON.stringify(dataverseData[index], null, 2)}</pre>
                ) : (
                  <Typography>No data available for this chart.</Typography>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MainLayout>
  );
};

export default Dashboard;
