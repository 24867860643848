// src/pages/Contractors.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../components/MainLayout';
import ContractorsLayout from '../styles/ContractorsLayout'; // Import layout from the styles folder

const Contractors = () => {
  const [userData, setUserData] = useState(null);
  const [data, setData] = useState([]); // Holds contractor data
  const [searchTerm, setSearchTerm] = useState(''); // Holds the search term
  const [selected, setSelected] = useState([]); // Keeps track of selected contractors

// Fetch Contractor Accounts data from the new API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {		  
		// Fetch user data from your Node.js backend, sending cookies with the request
        const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
          withCredentials: true, // Include cookies in the request
        });
        setUserData(userResponse.data);  
        // Fetch data from /api/dataverse/accounts
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/dataverse/crffc_contractoraccounts`, {
          withCredentials: true, // Include cookies in the request
        });

        setData(response.data); // Set client accounts data
      } catch (error) {
        console.error('Error fetching Contractor accounts:', error);
      }
    };

    fetchData();
  }, []);

  // Handle contractor selection
  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Filter contractors based on the search term
  const filteredContractors = data.filter((crffc_contractoraccounts) =>
    crffc_contractoraccounts.crffc_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <MainLayout userData={userData}> {/* Pass userData to MainLayout for use in the header */}
      {/* Pass the necessary props to the layout */}
      <ContractorsLayout
        data={filteredContractors.map(crffc_contractoraccounts => ({
			name: crffc_contractoraccounts.crffc_name || 'N/A',
			phone: crffc_contractoraccounts.crffc_mainphone || 'N/A',
			City: crffc_contractoraccounts.crffc_caddress1city || 'N/A',
			County: crffc_contractoraccounts.crffc_address1county || 'N/A',
			reference: crffc_contractoraccounts.crffc_internalreference || 'N/A',
			id: crffc_contractoraccounts.crffc_contractoraccountsid
			
		}))}
        selected={selected}
        handleSelect={handleSelect}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </MainLayout>
  );
};

export default Contractors;
