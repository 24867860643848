import React, { useEffect, useState } from 'react';
import '../css/ScrollingText.css'; // Make sure this path is correct

const ScrollingText = () => {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    // Fetch updates or use placeholder text
    const fetchUpdates = async () => {
      try {
        const response = await fetch('/api/updates'); // Ensure this endpoint is correct
        const data = await response.json();
        setUpdates(data);
      } catch (error) {
        console.error('Error fetching updates:', error);
        setUpdates(['Default update 1', 'Default update 2']); // Fallback content
      }
    };

    fetchUpdates();
  }, []);

  return (
    <div className="scrolling-text-container">
      <div className="scrolling-text">
        {updates.join(' | ')} {/* Ensure updates are displayed properly */}
      </div>
    </div>
  );
};

export default ScrollingText;
