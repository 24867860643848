// src/styles/KeysLayout.js
import React from 'react';
import {
  Grid, Card, CardContent, Typography, TextField, Checkbox, Box
} from '@mui/material';

const KeysLayout = ({ data, selected, handleSelect, searchTerm, setSearchTerm }) => {
  const isSelected = (id) => selected.includes(id);

  return (
    <>
      {/* Search Bar */}
      <TextField
        label="Search Keys"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Keys Grid */}
      <Grid container spacing={4}>
        {data.length > 0 ? (
          data.map((key) => (
            <Grid item xs={12} sm={6} md={4} key={key.keyid}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #ccc' }}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {key.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Type: {key.type || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Description: {key.description || 'No description available'}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Checkbox
                      checked={isSelected(key.keyid)}
                      onChange={() => handleSelect(key.keyid)}
                      inputProps={{ 'aria-label': 'select key' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      Select Key
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" align="center" sx={{ marginTop: 4 }}>
            No keys found.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default KeysLayout;
