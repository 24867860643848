// src/styles/ContractorContactsLayout.js
import React from 'react';
import {
  Grid, Card, CardContent, Typography, TextField, Checkbox, CardMedia, Box
} from '@mui/material';

const ContractorContactsLayout = ({ data, selected, handleSelect, searchTerm, setSearchTerm }) => {
  // Check if a contact is selected
  const isSelected = (id) => selected.includes(id);

  return (
    <>
      {/* Search Bar */}
      <TextField
        label="Search Contractor Contacts"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Contractor Contacts Grid */}
      <Grid container spacing={4}>
        {data.length > 0 ? (
          data.map((contact) => (
            <Grid item xs={12} sm={6} md={4} key={contact.contactid}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #ccc' }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={contact.imageUrl || 'https://via.placeholder.com/150'} // Placeholder if no image
                  alt={contact.fullname}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {contact.fullname}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Position: {contact.jobtitle || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Email: {contact.emailaddress1 || 'N/A'}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Checkbox
                      checked={isSelected(contact.contactid)}
                      onChange={() => handleSelect(contact.contactid)}
                      inputProps={{ 'aria-label': 'select contact' }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      Select Contact
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" align="center" sx={{ marginTop: 4 }}>
            No contractor contacts found.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default ContractorContactsLayout;
