import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import HouseIcon from '@mui/icons-material/House';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentIcon from '@mui/icons-material/Payment';
import QuoteIcon from '@mui/icons-material/RequestQuote';
import ConstructionIcon from '@mui/icons-material/Construction';
import InvoiceIcon from '@mui/icons-material/Receipt';

// New icons
import VpnKeyIcon from '@mui/icons-material/VpnKey';  // For Keys
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';  // For Fire Safety
import BoltIcon from '@mui/icons-material/Bolt';  // For Electrical Safety
import BuildCircleIcon from '@mui/icons-material/BuildCircle';  // For PAT Testing
import VideocamIcon from '@mui/icons-material/Videocam';  // For CCTV Installation
import SecurityIcon from '@mui/icons-material/Security';




const sidebarItems = [
  {
    panel: 'panel0',
    label: "Dashboard",
    icon: <DashboardIcon />,
    subItems: [
      { label: "Overview", icon: <DashboardIcon />, link: "/dashboard-overview" },
      { label: "Reports", icon: <ConstructionIcon />, link: "/dashboard-reports" }
    ]
  },
  {
    panel: 'panel1',
    label: "Accounts",
    icon: <AccountCircleIcon />,
    subItems: [
      { label: "Client Accounts", icon: <AccountCircleIcon />, link: "/client-accounts" },
      { label: "Contractor Accounts", icon: <PeopleIcon />, link: "/contractor-accounts" },
      { label: "Supplier Accounts", icon: <BusinessIcon />, link: "/supplier-accounts" }
    ]
  },
  {
    panel: 'panel2',
    label: "Contacts",
    icon: <PeopleIcon />,
    subItems: [
      { label: "Client Contacts", icon: <PeopleIcon />, link: "/client-contacts" },
      { label: "Contractor Contacts", icon: <PeopleIcon />, link: "/contractor-contacts" },
      { label: "Suppliers Contacts", icon: <BusinessIcon />, link: "/suppliers-contacts" }
    ]
  },
  {
    panel: 'panel3',
    label: "Projects",
    icon: <WorkIcon />,
    subItems: [
      { label: "New Quote Request", icon: <QuoteIcon />, link: "/new-quote-request" },
      { label: "New Work Order", icon: <BuildIcon />, link: "/new-work-order" },
      { label: "Projects", icon: <WorkIcon />, link: "/projects" }
    ]
  },
  {
    panel: 'panel4',
    label: "Properties",
    icon: <HouseIcon />,
    subItems: [
	  { label: "Properties", icon: <HouseIcon />, link: "/properties" },  // Updated icon for Properties
      { label: "Keys", icon: <VpnKeyIcon />, link: "/keys" },  // Updated icon for Keys
      { label: "Fire Safety", icon: <LocalFireDepartmentIcon />, link: "/fire-safety" },  // Updated icon for Fire Safety
      { label: "Gas Safety", icon: <GasMeterIcon />, link: "/gas-safety" },
      { label: "Electrical Safety", icon: <BoltIcon />, link: "/electrical-safety" },  // Updated icon for Electrical Safety
      { label: "PAT Testing", icon: <BuildCircleIcon />, link: "/pat-testing" },  // Updated icon for PAT Testing
      { label: "CCTV Installation", icon: <VideocamIcon />, link: "/cctv-installation" },  // Updated icon for CCTV Installation
	  { label: "Alarm Installation", icon: <SecurityIcon />, link: "/alarm-installation" }  // Updated icon for Alarm Installation
    ]
  },
  {
    panel: 'panel5',
    label: "Financials",
    icon: <InvoiceIcon />,
    subItems: [
      { label: "Quotes", icon: <QuoteIcon />, link: "/quotes" },
      { label: "New Quote", icon: <QuoteIcon />, link: "/new-quote" },
      { label: "Invoices", icon: <InvoiceIcon />, link: "/invoices" },
      { label: "New Invoice", icon: <InvoiceIcon />, link: "/new-invoice" },
      { label: "Purchase Orders", icon: <PaymentIcon />, link: "/purchase-orders" },
      { label: "New Purchase Order", icon: <PaymentIcon />, link: "/new-purchase-order" }
    ]
  },
  {
    panel: 'panel6',
	label: "Settings",
    icon: <SettingsIcon />,
    subItems: [
	  { label: "Settings", icon: <ConstructionIcon />, link: "/settings" },
      { label: "Profile", icon: <AccountCircleIcon />, link: "/user-profile" }      
    ]
  },
  {
    label: "Logout",
    icon: <LogoutIcon />,
    action: "logout"
  }
];

export default sidebarItems;
