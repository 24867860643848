import React from 'react';
import { Grid, Container, Box } from '@mui/material';

const LoginLayout = ({ image, children, logo }) => {  // Add logo prop
  return (
    <Grid container style={{ height: '100vh' }}>
      {/* Left side: Full-sized image */}
      <Grid item xs={12} md={6} style={{ background: `url(${image}) no-repeat center center/cover` }}>
        {/* Image occupies 50% of the screen */}
      </Grid>

      {/* Right side: Login form with logo */}
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Container maxWidth="sm" style={{ textAlign: 'center' }}>
            {logo && (
              <Box mb={3}>
                <img src={logo} alt="Company Logo" style={{ width: '300px' }} />  {/* Add logo */}
              </Box>
            )}
            {children}  {/* Render the children (login form) below the logo */}
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
