import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Accounts from './pages/Accounts';
import Contractors from './pages/Contractors';
import Suppliers from './pages/Suppliers';
import ClientContacts from './pages/ClientContacts';
import ContractorContacts from './pages/ContractorContacts';
import SuppliersContacts from './pages/SuppliersContacts';
import Projects from './pages/Projects';
import Properties from './pages/Properties';
import Keys from './pages/Keys';
import FireSafety from './pages/FireSafety';
import GasSafety from './pages/GasSafety';
import ElectricalSafety from './pages/ElectricalSafety';
import PATTesting from './pages/PATTesting';
import CCTVInstallation from './pages/CCTVInstallation';
import Quotes from './pages/Quotes';
import AlarmInstallation from './pages/AlarmInstallation';
import Invoices from './pages/Invoices';
import PurchaseOrders from './pages/PurchaseOrders';
import UserProfile from './pages/UserProfile';

function App() {
  return (
    <Router>
      <Routes>
        {/* Default route redirects to login */}
        <Route path="/" element={<Navigate to="/login" />} />
        {/* Defined routes for each component */}
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/client-accounts" element={<Accounts />} />
        <Route path="/contractor-accounts" element={<Contractors />} />
        <Route path="/supplier-accounts" element={<Suppliers />} />
        <Route path="/client-contacts" element={<ClientContacts />} />
        <Route path="/contractor-contacts" element={<ContractorContacts />} />
        <Route path="/suppliers-contacts" element={<SuppliersContacts />} />
        <Route path="/projects" element={<Projects />} />
		<Route path="/properties" element={<Properties />} />
		<Route path="/keys" element={<Keys />} />
		<Route path="/fire-safety" element={<FireSafety />} />
		<Route path="/gas-safety" element={<GasSafety />} />
		<Route path="/electrical-safety" element={<ElectricalSafety />} />
		<Route path="/pat-testing" element={<PATTesting />} />
		<Route path="/cctv-installation" element={<CCTVInstallation />} />
		<Route path="/quotes" element={<Quotes />} />
		<Route path="/alarm-installation" element={<AlarmInstallation />} />
		<Route path="/invoices" element={<Invoices />} />
		<Route path="/purchase-orders" element={<PurchaseOrders />} />
		<Route path="/user-profile" element={<UserProfile />} />
      </Routes>
    </Router>
  );
}

export default App;
